body {
  margin: 0;
}

.nav-bar {
  background-color: #7719aa;
  padding: 2em 2em 0 2em !important;
}

.nav-bar .ui.menu {
  align-items: center;
}

.nav-bar-menu {
  flex: 1 1;
}

.segment {
  box-shadow: none !important;
  border: 0 !important;
}

.main-content {
  margin: 2em !important;
  /* align-content: center;
  text-align: center; */
}

.article-card,
.sortBy-dropdown,
.user-card {
  margin: 0.5em !important;
}

.article-card .description,
.topic-card .description {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 10ch;
}

.topic-title {
  text-align: center;
}

